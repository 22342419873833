import React, { useState } from "react";
import { observer } from "mobx-react";
import Button from "../Button";
import Spinner from "../Spinner";
import PieTimer from "../PieTimer";
import PeopleStore from "../../../stores/PeopleStore";
import "./DeleteRelationshipModal.scss";

function DeleteRelationshipModal({ user, relationship, close, openInfoModal }) {
  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const enableDelete = () => setDeleteEnabled(true);
  const [deleting, setDeleting] = useState(false);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleDelete = async () => {
    setDeleting(true);
    const success = await PeopleStore.deleteRelationship(user.id, relationship.id);
    if (success) {
      const otherPerson = {
        firstName: relationship.firstName,
        lastName: relationship.lastName,
        id: relationship.id,
        userId: relationship.id
      };
      
      openInfoModal(otherPerson, true);
      close();
    }
    setDeleting(false);
  };

  const cancelButtonStyles = deleting ? { opacity: 0.1, pointerEvents: "none" } : {};
  const actionButtonStyles = deleting ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = deleting ? <Spinner /> : "Delete Relationship";

  const actionButton = deleteEnabled ? (
    <Button type="negative" action={handleDelete} style={actionButtonStyles}>
      {actionButtonContent}
    </Button>
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--red)" width={24} height={24} duration={3000} onComplete={enableDelete} />
    </div>
  );

  const getRelationshipDescription = () => {
    return `This will remove ${relationship.firstName} ${relationship.lastName} as ${user.firstName} ${user.lastName}'s ${relationship.relationship}`;
  };

  return (
    <div className="delete-relationship-modal">
      <div className="content">
        <div className="title">Delete Relationship</div>
        <div className="section relationship-info">
          <div className="section-header">RELATIONSHIP INFORMATION</div>
          <div className="relationship-users">
            <div>
              <span className="label">Current Profile: </span>
              {user.firstName} {user.lastName}
            </div>
            <div>
              <span className="label">{capitalizeFirstLetter(relationship.relationship)}: </span>
              {relationship.firstName} {relationship.lastName}
            </div>
          </div>
        </div>
        <div className="warning-section">
          <div className="section-header">WARNING</div>
          <div className="warning-text">
            <div className="description">{getRelationshipDescription()}</div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">{actionButton}</div>
      </div>
    </div>
  );
}

export default observer(DeleteRelationshipModal);