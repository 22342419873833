import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { components as RSComponents } from "react-select";
import { FiSearch, FiUser } from "react-icons/fi";
import PeopleStore from "../../../stores/PeopleStore";
import Button from "../Button";
import "./RelationshipPersonSearchInput.scss";

const styles = {
  container: provided => ({
    ...provided,
    width: "100%"
  }),
  control: provided => ({
    ...provided,
    border: "none",
    boxShadow: "var(--shadow)",
    borderRadius: 0
  }),
  placeholder: provided => ({
    ...provided,
    color: "var(--medium-gray)",
    opacity: 0.5
  }),
  loadingIndicator: provided => ({
    ...provided,
    color: "var(--dark-primary)"
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--dark-primary)"
        : state.isFocused
        ? "var(--super-light-gray)"
        : "var(--white)",
      ...(state.isSelected ? { fontWeight: "700" } : {}),
      cursor: "pointer",
      ":active": {
        ...provided[":active"],
        backgroundColor: state.isSelected ? "var(--dark-primary)" : "var(--dark-primary-transparent)"
      }
    };
  }
};

const getNoOptionsMessage = ({ inputValue }) => {
  if (!inputValue) return "Type something to search";
  else return "No results found... try altering your search a bit.";
};

const getOptionValue = o => o;

const getOptionLabel = o => `${o?.firstName} ${o?.lastName}`;

const ResultOption = props => {
  const { firstName, lastName, email, isParent, isStudent, isTeacher, infusionsoftId, userId: lpmId } =
    props?.value || {};

  const teacherChip = isTeacher ? (
    <div className="chip teacher" key="t">
      Teacher
    </div>
  ) : null;
  const parentChip = isParent ? (
    <div className="chip parent" key="p">
      Parent
    </div>
  ) : null;
  const studentChip = isStudent ? (
    <div className="chip student" key="s">
      Student
    </div>
  ) : null;
  const chips = [teacherChip, parentChip, studentChip].filter(Boolean);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.selectOption(props.data);
  };

  return (
    <RSComponents.Option {...props} onClick={handleClick}>
      <div className="search-result-wrapper">
        <div className="search-result-content">
          <div className="profile-image">
            <FiUser color="var(--medium-gray)" size={40} />
          </div>
          <div className="info-container">
            <div className="search-result-name">
              {firstName?.toProperCase()} {lastName?.toProperCase()}
            </div>
            <div className="search-result-email">{email}</div>
            <div className="search-result-id">InfusionSoft ID: {infusionsoftId}</div>
            <div className="search-result-id">LPM ID: {lpmId}</div>
            <div className="person-type-chips-container">{chips}</div>
          </div>
        </div>
      </div>
    </RSComponents.Option>
  );
};

const DropdownIndicator = props => {
  return (
    <RSComponents.DropdownIndicator {...props}>
      <FiSearch color="var(--medium-gray)" size={24} />
    </RSComponents.DropdownIndicator>
  );
};

const RelationshipPersonSearchInput = props => {
  const [search, setSearch] = useState("");
  const updateSearch = thing => setSearch(thing);

  const filterSearchResults = async (inputValue) => {
    if (!inputValue) return [];
    const results = await PeopleStore?.search(inputValue);
    // Filter out the current user from search results
    return results.filter(person => person.userId !== props.currentUserId);
  };

  const onChange = value => {
    if (typeof props?.onSelect === "function") props.onSelect(value);
  };

  const handleUnselect = () => {
    if (typeof props?.onSelect === "function") props.onSelect(null);
  };

  if (props?.value) {
    const { firstName, lastName, email, isParent, isStudent, isTeacher, infusionsoftId, userId: lpmId } = props.value;
    
    return (
      <div className="selected-person-container">
        <div className="selected-person-content">
          <div className="profile-image">
            <FiUser color="var(--medium-gray)" size={24} />
          </div>
          <div className="info-container">
            <div className="search-result-name">
              {firstName?.toProperCase()} {lastName?.toProperCase()}
            </div>
            <div className="search-result-email">{email}</div>
            <div className="search-result-id">InfusionSoft ID: {infusionsoftId}</div>
            <div className="search-result-id">LPM ID: {lpmId}</div>
            <div className="person-type-chips-container">
              {isTeacher && <div className="chip teacher">Teacher</div>}
              {isParent && <div className="chip parent">Parent</div>}
              {isStudent && <div className="chip student">Student</div>}
            </div>
          </div>
        </div>
        <Button type="negative" action={handleUnselect}>
          Change Person
        </Button>
      </div>
    );
  }

  return (
    <AsyncSelect
      {...props}
      styles={styles}
      placeholder="Search People"
      inputValue={search}
      value={null}
      onInputChange={updateSearch}
      onChange={onChange}
      isMulti={false}
      noOptionsMessage={getNoOptionsMessage}
      loadOptions={filterSearchResults}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: ResultOption }}
    />
  );
};

export default RelationshipPersonSearchInput;