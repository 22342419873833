import React from "react";
import Button from "../Button";
import "./RelationshipInfoModal.scss";

function RelationshipInfoModal({ isDelete = false, user, otherPerson, close }) {
  const action = isDelete ? "deleted from" : "added to";
  
  return (
    <div className="relationship-info-modal">
      <div className="content">
        <div className="title">Relationship {isDelete ? "Deleted" : "Added"}</div>
        <div className="section">
          <div className="info-text">
            <p>
              This relationship was {action} <strong>{user.firstName} {user.lastName}'s</strong> profile only.
            </p>
            <p>
              To maintain this relationship in both profiles, you'll need to 
              {isDelete ? " also remove it from" : " also add it to"} <strong>{otherPerson.firstName} {otherPerson.lastName}'s</strong> profile.
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button type="cta" action={close}>Got it</Button>
      </div>
    </div>
  );
}

export default RelationshipInfoModal;