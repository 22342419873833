import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Button from "../Button";
import Spinner from "../Spinner";
import Select from "react-select";
import RelationshipPersonSearchInput from "../RelationshipPersonSearchInput";
import PeopleStore from "../../../stores/PeopleStore";
import "./AddRelationshipModal.scss";
import { FiUser } from "react-icons/fi";

const RELATIONSHIP_OPTIONS = [
  { value: 'parent', label: 'Parent' },
  { value: 'child', label: 'Child' },
  { value: 'sibling', label: 'Sibling' },
  { value: 'teacher', label: 'Teacher' }
];


function AddRelationshipModal({ user, close, openInfoModal }) {
  const [loading, setLoading] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedRelationship, setSelectedRelationship] = useState(null);

  // Get existing relationships for the selected person
  const getExistingRelationships = () => {
    if (!selectedPerson) return [];
    
    return user.connections
      ?.filter(connection => connection.id === selectedPerson.userId)
      ?.map(connection => connection.relationship);
  };

  // Filter out relationship options that already exist
  const getAvailableRelationshipOptions = () => {
    const existingRelationships = getExistingRelationships();
    
    return RELATIONSHIP_OPTIONS.filter(option => 
      !existingRelationships.includes(option.value)
    );
  };

  // Reset relationship selection when person changes
  useEffect(() => {
    setSelectedRelationship(null);
  }, [selectedPerson]);

  const getRelationshipDescription = () => {
    if (!selectedPerson || !selectedRelationship) return '';

    return `Add ${selectedPerson.firstName} as ${user.firstName}'s ${selectedRelationship.value}`;
  };

  const handleCreate = async () => {
    if (!selectedPerson || !selectedRelationship) return;

    setLoading(true);
    const success = await PeopleStore.createRelationship(
      user.id,
      selectedPerson.userId,
      selectedRelationship.value
    );
    if (success) {
      const otherPerson = {
        firstName: selectedPerson.firstName,
        lastName: selectedPerson.lastName,
        id: selectedPerson.userId,
        userId: selectedPerson.userId
      };
      
      console.log('About to close modal and show info', { otherPerson });
      close();
      console.log('Modal closed, showing info modal');
      openInfoModal(otherPerson, false);
      console.log('Info modal called');
    }
    setLoading(false);
  };

  const renderRelationshipSummary = () => {
    if (!selectedPerson || !selectedRelationship) return null;

    return (
      <div className="section relationship-summary">
        <div className="section-header">RELATIONSHIP SUMMARY</div>
        <div className="summary-content">
          <div className="summary-users">
            <div className="user current">
              <div className="profile-image">
                <FiUser color="var(--medium-gray)" size={24} />
              </div>
              <div className="user-info">
                <div className="name">{user.firstName} {user.lastName}</div>
                <div className="description">Current Profile</div>
              </div>
            </div>
            <div className="relationship-arrow">
              <div className="arrow-label">{getRelationshipDescription()}</div>
            </div>
            <div className="user selected">
              <div className="profile-image">
                <FiUser color="var(--medium-gray)" size={24} />
              </div>
              <div className="user-info">
                <div className="name">{selectedPerson.firstName} {selectedPerson.lastName}</div>
                <div className="description">Selected Person</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="add-relationship-modal">
      <div className="content">
        <div className="title">Add Relationship</div>
        <div className="section">
          <div className="section-header">
            {selectedPerson ? "SELECTED PERSON" : `SEARCH FOR A PERSON`}
          </div>
          <RelationshipPersonSearchInput
            onSelect={setSelectedPerson}
            value={selectedPerson}
            hasValue={!!selectedPerson}
            currentUserId={user.id}
          />
        </div>
        {selectedPerson && (
          <div className="section">
            <div className="section-header">RELATIONSHIP TYPE</div>
            {getAvailableRelationshipOptions().length > 0 ? (
              <Select
                placeholder="Select relationship type..."
                options={getAvailableRelationshipOptions()}
                onChange={setSelectedRelationship}
                value={selectedRelationship}
              />
            ) : (
              <div className="no-relationships-available">
                All possible relationships already exist with this person
              </div>
            )}
          </div>
        )}
        {renderRelationshipSummary()}
      </div>
      <div className="bottom-buttons">
        <Button action={close}>Cancel</Button>
        <div className="left-buttons">
          <Button
            type="cta"
            action={handleCreate}
            disabled={!selectedPerson || !selectedRelationship || loading}
          >
            {loading ? <Spinner /> : 'Add Relationship'}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddRelationshipModal);