import { makeAutoObservable, runInAction } from "mobx";
import debounce from "debounce-promise";
import { parseISO } from "date-fns";
import { ENV } from "../config";
import { request } from "../utils";
import { showSuccessToast, showErrorToast } from "../services/ToastService";

const RECENTLY_VIEWED_KEY = "lpm:admin:recentlyviewed";
const MAX_RECENTLY_VIEWED = 12;
class PeopleStore {
  constructor() {
    makeAutoObservable(this);
    this.loadRecentlyViewed();
  }

  loading = false;
  loadingPeople = {};
  loadingMaterialsForParents = {};
  loadingAllClasses = {};
  loadingCardsByParentId = {};

  rawRecentlyViewed = [];

  get recentlyViewed() {
    return this.rawRecentlyViewed?.slice()?.reverse();
  }

  rawPeople = [];

  get people() {
    return this.rawPeople.map(p => ({
      ...p,
      connectionsByRelationship: p?.connections?.reduce((acc, next) => {
        if (next?.relationship) {
          if (acc[next.relationship]) acc[next.relationship] = acc[next.relationship].concat(next);
          else acc[next.relationship] = [next];
        }
        return acc;
      }, {}),
      teacherProfile: p?.teacherProfile
        ? {
          ...p.teacherProfile,
          shippingDates: p.teacherProfile?.shippingDates
            ? {
              ...p.teacherProfile.shippingDates,
              winter: p.teacherProfile?.shippingDates?.winter
                ? parseISO(p.teacherProfile?.shippingDates?.winter)
                : null,
              fall: p.teacherProfile?.shippingDates?.fall ? parseISO(p.teacherProfile?.shippingDates?.fall) : null,
              summer: p.teacherProfile?.shippingDates?.summer
                ? parseISO(p.teacherProfile?.shippingDates?.summer)
                : null
            }
            : null
        }
        : null
    }));
  }

  get peopleById() {
    return Object.fromEntries(this.people?.map(p => [p?.id, p]));
  }

  rawMaterialsByParentId = {};

  get materialsByParentId() {
    const mapped = Object.entries(this.rawMaterialsByParentId)?.map(([pid, materials]) => {
      return [pid, materials?.map(m => m)];
    });
    return Object.fromEntries(mapped);
  }

  rawSearchResults = [];

  get searchResults() {
    return this.rawSearchResults.map(sr => sr);
  }

  rawTeacherSearchResults = [];

  get teacherSearchResults() {
    return this.rawTeacherSearchResult?.map(t => t);
  }

  rawParentSearchResults = [];

  get parentSearchResults() {
    return this.rawParentSearchResult?.map(p => p);
  }

  rawCardsForParents = {};

  get cardsForParents() {
    const mapped = Object.entries(this.rawCardsForParents)?.map(([pid, cards]) => [
      pid,
      cards?.map(c => ({
        ...c,
        label: `${c?.card_type} ending in ${c?.card_number?.replace(/x/g, "")}`
      }))
    ]);
    return Object.fromEntries(mapped);
  }

  async fetchPersonById(userId, noRefresh) {
    if (noRefresh && this.peopleById?.[userId]) return this.peopleById?.[userId];

    try {
      if (!this.loadingPeople[userId]) {
        this.loadingPeople = { ...this.loadingPeople, [userId]: true };
        const person = await request.get(`/users/${userId}`);
        this.rawPeople = this.rawPeople?.concat(person);
        this.loadingPeople = { ...this.loadingPeople, [userId]: false };
        return person;
      }
    } catch (err) {
      this.loadingPeople = { ...this.loadingPeople, [userId]: false };
      console.warn(err);
    }
  }

  async fetchMaterialsForParent(parentId, noRefresh) {
    if (noRefresh && this.materialsByParentId?.[parentId]) return this.materialsByParentId?.[parentId];

    try {
      if (!this.loadingMaterialsForParents[parentId]) {
        this.loadingMaterialsForParents = { ...this.loadingMaterialsForParents, [parentId]: true };
        const materials = await request.get(`/users/${parentId}/materials`);
        this.rawMaterialsByParentId = { ...this.rawMaterialsByParentId, [parentId]: materials };
        this.loadingMaterialsForParents = { ...this.loadingMaterialsForParents, [parentId]: false };
        return materials;
      }
    } catch (err) {
      this.loadingMaterialsForParents = { ...this.loadingMaterialsForParents, [parentId]: false };
      console.warn(err);
    }
  }

  async rawSearch(term) {
    this.loading = true;
    try {
      const searchResults = await request.get(`/search?q=${term}`);
      this.rawSearchResults = searchResults;
      this.loading = false;
      return searchResults;
    } catch (err) {
      console.warn(err);
      this.loading = false;
      return [];
    }
  }

  search = debounce(search => this.rawSearch(search), 300, { leading: true });

  async rawSearchTeachers(term) {
    this.loading = true;
    try {
      const rawSearchResults = await request.get(`/search/teachers?q=${term}`);
      const searchResults = rawSearchResults.map(tsr => ({
        ...tsr,
        classes: tsr?.classes?.map(c => ({
          ...c,
          courseLogo: `https://${ENV}-lpm-assets.b-cdn.net/icons/${c?.courseId}?m=${c?.modified}`
        }))
      }));
      this.rawTeacherSearchResults = searchResults;
      this.loading = false;
      return searchResults;
    } catch (err) {
      console.warn(err);
      this.loading = false;
      return [];
    }
  }

  searchParents = debounce(search => this.rawSearchParents(search), 300, { leading: true });

  async rawSearchParents(term) {
    this.loading = true;
    try {
      const rawSearchResults = await request.get(`/search/parents?q=${term}`);
      const searchResults = rawSearchResults;
      this.rawParentSearchResults = searchResults;
      this.loading = false;
      return searchResults;
    } catch (err) {
      console.warn(err);
      this.loading = false;
      return [];
    }
  }

  searchTeachers = debounce(search => this.rawSearchTeachers(search), 300, { leading: true });

  async createCognitoAccount(user, password) {
    const { id, infusionsoftId, email, firstName } = user || {};
    try {
      const { success, updatedUserProfile } = await request.post("/users/cognito/create", {
        body: { lpmId: id, infusionsoftId, email: email[0]?.email, password }
      });
      if (success) {
        showSuccessToast(`Successfully created ${firstName}'s account.`);
        this.rawPeople = this.rawPeople?.map(p => {
          if (p?.id === id) return { ...p, ...updatedUserProfile };
          return p;
        });
        return true;
      } else throw new Error("Error creating cognito account for user.");
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error creating ${firstName}'s account.`);
      return false;
    }
  }

  async changePasswordForUser(user, password) {
    const { id, firstName } = user || {};
    try {
      const { success } = await request.post("/users/cognito/changepassword", { body: { lpmId: id, password } });
      if (success) {
        showSuccessToast(`Successfully changed ${firstName}'s password.`);
        return true;
      } else throw new Error("Error changing password for user.");
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error changing ${firstName}'s password.`);
      return false;
    }
  }

  async changeEmailForUser(user, email) {
    const { id, firstName } = user || {};
    try {
      const { emailExists, success, updatedUserProfile } = await request.post("/users/cognito/changeemail", {
        body: { lpmId: id, email }
      });
      if (emailExists) {
        showErrorToast(`A user with that email address already exists.`);
        return false;
      } else if (success) {
        this.rawPeople = this.rawPeople?.map(p => {
          if (p?.id === id) return { ...p, ...updatedUserProfile };
          return p;
        });
        showSuccessToast(`Successfully changed ${firstName}'s email.`);
        return true;
      } else throw new Error("Error changing email for user.");
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error changing ${firstName}'s email.`);
      return false;
    }
  }

  async changeInfusionsoftIdForUser(user, newInfusionsoftId) {
    const { id, firstName } = user || {};
    try {
      const { success, updatedUserProfile } = await request.post("/users/cognito/changeinfusionsoftid", {
        body: { lpmId: id, newInfusionsoftId }
      });
      if (success) {
        this.rawPeople = this.rawPeople?.map(p => {
          if (p?.id === id) return { ...p, ...updatedUserProfile };
          return p;
        });
        showSuccessToast(`Successfully changed ${firstName}'s InfusionSoft ID.`);
        return true;
      } else {
        throw new Error("Error changing InfusionSoft ID for user.");
      }
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error changing ${firstName}'s InfusionSoft ID.`);
      return false;
    }
  }

  async syncUserWithInfusionsoft(user) {
    const { id, infusionsoftId, firstName } = user || {};
    try {
      this.loadingPeople = { ...this.loadingPeople, [id]: true };
      const updatedPerson = await request.post(`/users/sync?lpmId=${id}&infusionsoftId=${infusionsoftId}`);
      this.rawPeople = this.rawPeople?.map(p => {
        if (p?.id === updatedPerson?.id) return updatedPerson;
        return p;
      });
      this.loadingPeople = { ...this.loadingPeople, [id]: false };
      showSuccessToast(`Successfully synced ${firstName}'s data with InfusionSoft.`);
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error syncing ${firstName}'s data with InfusionSoft.`);
      return false;
    }
  }

  async syncUserByInfusionsoftId(infusionsoftId) {
    try {
      const updatedPerson = await request.post(`/users/sync?infusionsoftId=${infusionsoftId}`);
      this.rawPeople = this.rawPeople?.map(p => {
        if (p?.id === updatedPerson?.id) return updatedPerson;
        return p;
      });
      showSuccessToast(`Successfully synced ${updatedPerson?.firstName}'s data with InfusionSoft.`);
      return updatedPerson;
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error fixing missing user.`);
      return false;
    }
  }

  async fixUserData(errors) {
    try {
      await request.post(`/users/fix`, { body: { errors } });
      showSuccessToast(`Success! Data is fixed.`);
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error! Please reach out to T&T.`);
      return false;
    }
  }

  async mergeUsers({ isCurrentToTarget, current, target }) {
    try {
      const fromLPMId = isCurrentToTarget ? current?.id : target?.userId;
      const fromInfusionsoftId = isCurrentToTarget ? current?.infusionsoftId : target?.infusionsoftId;
      const toLPMId = isCurrentToTarget ? target?.userId : current?.id;
      const toInfusionsoftId = isCurrentToTarget ? target?.infusionsoftId : current?.infusionsoftId;
      await request.post(`/users/merge`, { body: { fromLPMId, toLPMId, fromInfusionsoftId, toInfusionsoftId } });
      showSuccessToast(`Successfully merged users.`);
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error merging users.`);
      return false;
    }
  }

  async updateStudioLocationForTeacher({ userId, currentStudio, updatedStudio }) {
    try {
      const updatedTeacherProfile = await request.put(`/users/${userId}/studios/update`, {
        body: { currentStudio, updatedStudio }
      });
      await this.fetchPersonById(userId);
      this.rawPeople = this.rawPeople?.map(p => {
        if (p?.id === userId) return { ...p, teacherProfile: updatedTeacherProfile };
        return p;
      });
      showSuccessToast("Successfully updated teacher's studio location.");
    } catch (err) {
      console.warn(err);
      showErrorToast("Error updating teacher's studio location.");
    }
  }

  async fetchCardsForParent(parentId, parentInfusionsoftId) {
    this.loadingCardsByParentId = { ...this.loadingCardsByParentId, [parentId]: true };
    try {
      const cards = await request.get(`/users/${parentId}/cards?infusionsoftId=${parentInfusionsoftId}`);
      this.rawCardsForParents = { ...this.rawCardsForParents, [parentId]: cards };
      this.loadingCardsByParentId = { ...this.loadingCardsByParentId, [parentId]: false };
      return cards;
    } catch (err) {
      console.warn(err);
      this.loadingCardsByParentId = { ...this.loadingCardsByParentId, [parentId]: false };
    }
  }

  async createCardForParent(
    parentId,
    parentInfusionsoftId,
    parentEmail,
    { number, brand, expMonth, expYear, name, cvv }
  ) {
    this.creatingCardByParentId = { ...this.creatingCardByParentId, [parentId]: true };
    try {
      const newCard = await request.post(
        `/users/${parentId}/cards?infusionsoftId=${parentInfusionsoftId}&email=${parentEmail}`,
        {
          body: {
            cardNumber: number,
            cardType: brand,
            emailAddress: this.user?.email,
            expirationMonth: expMonth,
            expirationYear: expYear,
            cardName: name,
            verificationCode: cvv
          }
        }
      );
      this.rawCardsForParents = {
        ...this.rawCardsForParents,
        [parentId]: (this.rawCardsForParents?.[parentId] || []).concat(newCard)
      };
      this.creatingCardByParentId = { ...this.creatingCardByParentId, [parentId]: false };
      return newCard;
    } catch (err) {
      console.warn(err);
      this.creatingCardByParentId = { ...this.creatingCardByParentId, [parentId]: false };
    }
  }

  async createChildForParent(parent, childParams) {
    try {
      await request.post(`/users/${parent?.id}/children`, {
        body: {
          ...childParams,
          parent: { infusionsoftId: parent?.infusionsoftId, email: parent?.email?.[0]?.email }
        }
      });
      await this.fetchPersonById(parent?.id);
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  async hideUserStorefront(userId) {
    try {
      const updatedTeacherProfile = await request.put(`/users/${userId}/teacher/disable`);
      this.rawPeople = this.rawPeople?.map(p => {
        if (p?.id === userId) return { ...p, teacherProfile: updatedTeacherProfile };
        return p;
      });
      showSuccessToast("Successfully hid teacher's storefront.");
    } catch (err) {
      console.warn(err);
      showErrorToast("Error hiding teacher's storefront.");
    }
  }

  async showUserStorefront(userId) {
    try {
      const updatedTeacherProfile = await request.put(`/users/${userId}/teacher/enable`);
      this.rawPeople = this.rawPeople?.map(p => {
        if (p?.id === userId) return { ...p, teacherProfile: updatedTeacherProfile };
        return p;
      });
      showSuccessToast("Successfully enabled teacher's storefront.");
    } catch (err) {
      console.warn(err);
      showErrorToast("Error enabling teacher's storefront.");
    }
  }

  async toggleUserStorefront(userId) {
    const disabled = this.peopleById?.[userId]?.teacherProfile?.disabled;
    if (disabled) await this.showUserStorefront(userId);
    else await this.hideUserStorefront(userId);
  }

  async toggleRestricted(userId) {
    try {
      const restricted = !this.peopleById?.[userId]?.restricted;
      const updatedProfile = await request.put(`/users/${userId}`, {
        body: { restricted }
      });
      this.rawPeople = this.rawPeople?.map(p => {
        if (p?.id === userId) return { ...p, restricted: updatedProfile?.restricted };
        return p;
      });
      await this.fetchPersonById(userId);
      const successMessage = restricted
        ? "Successfully marked teacher as restricted"
        : "Successfully removed restriction from teacher";
      showSuccessToast(successMessage);
    } catch (err) {
      console.warn(err);
      showErrorToast("Error changing restriction for teacher");
    }
  }

  async updateShippingDatesForUser({ userId, shippingDates }) {
    if (!userId) return;
    try {
      const updatedTeacherProfile = await request.put(`/users/${userId}/teacher/shipping`, { body: { shippingDates } });
      this.rawPeople = this.rawPeople?.map(p => {
        if (p?.id === userId) return { ...p, teacherProfile: updatedTeacherProfile };
        return p;
      });
      await this.fetchPersonById(userId);
      showSuccessToast("Successfully updated teacher's shipping dates.");
    } catch (err) {
      console.warn(err);
      showErrorToast("Error updating teacher's shipping dates.");
    }
  }

  loadRecentlyViewed() {
    try {
      const encodedRecentlyViewedString = localStorage.getItem(RECENTLY_VIEWED_KEY);
      const decodedRecentlyViewedString = window.atob(encodedRecentlyViewedString);
      const rawRecentlyViewed = JSON.parse(decodedRecentlyViewedString);
      this.rawRecentlyViewed = rawRecentlyViewed;
    } catch (err) {
      this.rawRecentlyViewed = [];
    }
  }

  addToRecentlyViewed(person) {
    this.rawRecentlyViewed = this.rawRecentlyViewed
      ?.filter(p => p?.userId !== person?.userId)
      ?.concat(person)
      ?.slice(-MAX_RECENTLY_VIEWED);
    const rawRecentlyViewedString = JSON.stringify(this.rawRecentlyViewed);
    const encodedRecentlyViewedString = window.btoa(rawRecentlyViewedString);
    localStorage.setItem(RECENTLY_VIEWED_KEY, encodedRecentlyViewedString);
  }

  updateClassForTeacher({ teacherId, classId, updatedClass }) {
    this.rawPeople = this.rawPeople?.map(p => {
      if (p?.id === teacherId) {
        return {
          ...p,
          classes: p?.classes?.map(c => {
            if (c?.id === classId) return updatedClass;
            return c;
          })
        };
      }
      return p;
    });
  }

  async deleteRelationship(userId, relationshipId) {
    try {
      const success = await request.delete('/relationships', {
        body: { userId, relationshipId }
      });
      if (success) {
        this.rawPeople = this.rawPeople?.map(p => {
          if (p.id === userId || p.id === relationshipId) {
            return {
              ...p,
              connections: p.connections?.filter(c => 
                !(c.id === relationshipId || c.id === userId)
              )
            };
          }
          return p;
        });
        
        await this.fetchPersonById(userId);
        
        showSuccessToast('Successfully deleted relationship.');
        return true;
      }
      throw new Error('Failed to delete relationship');
    } catch (err) {
      console.warn(err);
      showErrorToast('Error deleting relationship.');
      return false;
    }
  }

  async createRelationship(userId, relationshipId, relationship) {
    try {
      const success = await request.post('/relationships', {
        body: { 
          userId,
          relationshipId,
          relationship
        }
      });

      if (success) {
        // Fetch updated person data using the correct endpoint
        const updatedPerson = await request.get(`/users/${userId}`);
        
        // Update the store
        runInAction(() => {
          this.rawPeople = this.rawPeople.map(p => {
            if (p.id === userId) {
              return updatedPerson;
            }
            return p;
          });
        });

        showSuccessToast('Successfully created relationship.');
        return true;
      }
      throw new Error('Failed to create relationship');
    } catch (err) {
      console.warn(err);
      showErrorToast('Error creating relationship.');
      return false;
    }
  }

  // Helper method to update a person in the store
  updatePersonInStore(person) {
    // Update in people array if it exists there
    const peopleIndex = this.people.findIndex(p => p.id === person.id);
    if (peopleIndex !== -1) {
      this.people[peopleIndex] = person;
    }

    // Update in currentPerson if it's the same person
    if (this.currentPerson?.id === person.id) {
      this.currentPerson = person;
    }
  }

  clear() {
    this.loading = false;
    this.loadingPeople = {};
    this.rawPeople = [];
  }
}

export default new PeopleStore();
